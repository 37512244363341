import React from "react"
import { scaleLinear } from "d3-scale"
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule
} from "react-simple-maps"
import indigo from "@material-ui/core/colors/indigo"

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json"

const colorScale = scaleLinear()
  .domain([0.29, 0.68])
  .range([indigo[100], indigo[500]])

const MapChart = props => {
  console.log(props.period)
  return (
    <ComposableMap
      projectionConfig={{
        rotate: [-10, 0, 0],
        scale: 147
      }}>
      <Sphere stroke='#E4E5E6' strokeWidth={0.5} />
      <Graticule stroke='#E4E5E6' strokeWidth={0.5} />
      {props.data[props.period].length > 0 && (
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => {
              const d = props.data[props.period].find(
                s => s.geoCode === geo.properties.ISO_A2
              )
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d.value / 100 + 0.2) : "#F5F4F6"}
                />
              )
            })
          }
        </Geographies>
      )}
    </ComposableMap>
  )
}

export default MapChart
