import React, { useState } from "react"
import "./App.css"
import MapChart from "./components/map"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Slider from "@material-ui/core/Slider"
import TextField from "@material-ui/core/TextField"
import LinearProgress from "@material-ui/core/LinearProgress"
import Chip from "@material-ui/core/Chip"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 5,
      marginRight: 5
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
      width: 1000
    },

    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
    marginBottom: 100
  },
  mainTextInput: {
    width: 700,
    maxWidth: "70%",
    marginTop: 50,
    marginBottom: 50
  },
  margin: {
    height: theme.spacing(3)
  },
  map: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      marginTop: "-100px",
      marginBottom: "-100px"
    }
  },
  chips: {
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "50%"
    },
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  sliderWrapper: {
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto"
  }
}))

const trends = [
  "Donald Trump",
  "Fridays for Future",
  "iPhone",
  "Area 51",
  "Democracy",
  "NFL",
  "JavaScript",
  "Skiing"
]

function App() {
  const [searchTerm, setSearchTerm] = useState("")
  const [currentSearchTerm, setCurrentSearchTerm] = useState("")
  const [period, setPeriod] = useState(2020)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})
  const classes = useStyles()

  const handleSubmit = event => {
    event.preventDefault()
    searchFor(searchTerm)
  }

  const searchFor = term => {
    setIsLoading(true)
    setData({})
    fetch("https://trendsapi.now.sh/api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ searchTerm: term })
    })
      .then(res => res.text())
      .then(res => {
        const newData = JSON.parse(JSON.parse(res).body)
        setData(newData)
        setCurrentSearchTerm(term)
        setPeriod(
          Object.keys(newData).map(element => parseInt(element))[
            Object.keys(newData).length - 1
          ]
        )
        setIsLoading(false)
      })
  }

  return (
    <div className='App'>
      <div className={classes.root}>
        <form onSubmit={handleSubmit}>
          <TextField
            autoComplete='off'
            className={classes.mainTextInput}
            id='outlined-basic'
            label='Look up a trend'
            variant='outlined'
            disabled={isLoading}
            onChange={event => setSearchTerm(event.target.value)}
          />
        </form>
        {currentSearchTerm.length === 0 && isLoading === false ? (
          <Grid className={classes.chips} container justify='center'>
            {trends.map(trend => (
              <Chip
                label={trend}
                onClick={event => {
                  searchFor(trend)
                }}
                variant='outlined'
              />
            ))}
          </Grid>
        ) : null}

        {isLoading ? <LinearProgress /> : null}
        {Object.keys(data).length ? (
          <div>
            <Typography id='discrete-slider' gutterBottom>
              Trend Lookup: <b>'{currentSearchTerm}'</b> based on data from
              trends.google.com
            </Typography>
            {Object.keys(data).length > 1 ? (
              <div className={classes.sliderWrapper}>
                <Slider
                  defaultValue={Object.keys(data)[Object.keys(data).length - 1]}
                  onChange={(event, newValue) => setPeriod(newValue.toString())}
                  valueLabelDisplay='auto'
                  step={1}
                  marks
                  min={Object.keys(data).map(element => parseInt(element))[0]}
                  max={
                    Object.keys(data).map(element => parseInt(element))[
                      Object.keys(data).length - 1
                    ]
                  }
                />
              </div>
            ) : null}
            <div className={classes.map}>
              <MapChart data={data} period={period} />
            </div>
          </div>
        ) : null}
      </div>
      <footer>
        <p>
          Copyright 2020 Thomas Übellacker (thomas@uebellacker.net - Schmidweg
          2, 3332 Rosenau)
        </p>
      </footer>
    </div>
  )
}

export default App
